import "./quiz.css";
import {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";

const QUIZ_PROMPT = 0;
const QUIZ_TAKE = 1;
const QUIZ_GRADE = 2;

const UNPUBLISHED = 0;
const PUBLISHED = 1;
const GRADED = 2;

// const URL_PATH = "http://localhost:80";
// const URL_PATH = "http://cs405.localhost/api";
const URL_PATH = "https://cs405.dariencampbell.com/api";

function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function QuizManager() {
    const { hash } = useLocation();
    console.log(hash);
    const [publishState, setPublishState] = useState(UNPUBLISHED);
    const [quizData, setQuizData] = useState(null);
    const [quizMode, setQuizMode] = useState(QUIZ_PROMPT);
    const [prompt, setPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(undefined);
    const [grades, setGrades] = useState([]);
    const [gradeReasons, setGradeReasons] = useState([]);
    const [editRequest, setEditRequest] = useState([]);

    useEffect(() => {
        if (!(hash && hash[0] === '#')) {
            return;
        }
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"load_quiz", key: hash})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                console.log("data: ", data);
                if (data && data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                console.log("loaded data: ", data);
                setQuizData(data.quizData);
                setGrades(data.grades);
                setGradeReasons(data.gradeReasons);
                if (data.graded) {
                    setQuizMode(QUIZ_GRADE);
                    setPublishState(GRADED);
                } else {
                    setQuizMode(QUIZ_TAKE);
                    setPublishState(PUBLISHED);
                }
                setLoading(false);
                setErrorMsg(undefined);
            }).catch(err => {
            console.error(err);
            setLoading(false);
            setErrorMsg(err.message);
        });
    }, [hash]);

    function finalizeGrade(grades, gradeReasons) {
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"save_quiz_grade", key: hash, grades: grades, gradeReasons: gradeReasons})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                if (data && data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                setPublishState(GRADED);
                setLoading(false);
                setErrorMsg(undefined);
            }).catch(err => {
            console.error(err);
            setLoading(false);
            setErrorMsg(err.message);
        });
    }

    function handlePromptClick() {
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"create_quiz", prompt: prompt})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                data = JSON.parse(data.ai_response);
                if (data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                setQuizData(data);
                setQuizMode(QUIZ_TAKE);
                setLoading(false);
                setErrorMsg(undefined);
            }).catch(err => {
                console.error(err);
                setLoading(false);
                setErrorMsg(err.message);
            });
    }

    function handleNewPromptClick() {
        setQuizMode(QUIZ_PROMPT);
    }

    function handleGradeClick(answers) {
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"grade_quiz", data: quizData, answers: answers})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                data = JSON.parse(data.ai_response);
                if (data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                // setQuizData(data);
                setLoading(false);
                setErrorMsg(undefined);
                setGrades(data.grades);
                setGradeReasons(data.reasons);
                setQuizMode(QUIZ_GRADE);
                console.log("check if final grade");
                if (publishState !== UNPUBLISHED) {
                    console.log("finalize grade");
                    finalizeGrade(data.grades, data.reasons);
                }
            }).catch(err => {
                console.error(err);
                setLoading(false);
                setErrorMsg(err.message);
            });
    }

    function handlePromptChange(value) {
        setPrompt(value);
    }

    function handleEditRequestChange(value) {
        setEditRequest(value);
    }

    function handleEditRequestClick() {
        console.log(editRequest);
        setLoading(true);
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"modify_quiz", data: quizData, prompt: editRequest})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                data = JSON.parse(data.ai_response);
                if (data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                setQuizData(data);
                setQuizMode(QUIZ_TAKE);
                setLoading(false);
                setErrorMsg(undefined);
            }).catch(err => {
            console.error(err);
            setLoading(false);
            setErrorMsg(err.message);
        });
    }

    function handleQuizEditClick() {
        setQuizMode(QUIZ_TAKE);
    }

    function handleQuizPublish() {
        setLoading(true);
        const hash = uuidv4();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify({method:"save_quiz", data: quizData, key: "#" + hash})
        };
        fetch(URL_PATH, options)
            .then(res => res.json())
            .then(data => {
                if (data.error) {
                    console.error(data.error);
                    setLoading(false);
                    setErrorMsg(data.error);
                    return;
                }
                setLoading(false);
                setErrorMsg(undefined);
                window.location.hash = hash;
            }).catch(err => {
            console.error(err);
            setLoading(false);
            setErrorMsg(err.message);
        });
    }

    let out;
    if (quizMode === QUIZ_PROMPT) {
        out = <QuizPrompt onClick={handlePromptClick} onPromptChange={handlePromptChange} error={errorMsg} loading={loading} />;
    } else {
        out = <Quiz publishState={publishState} data={quizData} onQuizPublish={handleQuizPublish} onNewPromptClick={handleNewPromptClick} onGradeClick={handleGradeClick} onEditRequestChange={handleEditRequestChange} onEditRequestClick={handleEditRequestClick} onQuizEditClick={handleQuizEditClick} grade={quizMode === QUIZ_GRADE} loading={loading} grades={grades} gradeReasons={gradeReasons} error={errorMsg} />;
    }

    return (
        <div className="quiz">
            {out}
        </div>
    );
}

function Quiz(props) {
    const data = props.data;
    const [answers, setAnswers] = useState((new Array(data.questions.length)).fill(undefined));

    function handleInput(qid, value) {
        let list = [...answers];
        list[qid] = value;
        setAnswers(list);
    }

    const out = [];
    let numCorrect = 0;
    out.push(<h1 key="header">{props.data.title ? props.data.title : "Assignment"}</h1>);
    if (props.error) {
        out.push(<ErrorBanner>{props.error}</ErrorBanner>);
    }
    for (let i = 0; i < data.questions.length; i++) {
        let q = data.questions[i];
        let content = null;
        if (q.options !== undefined) {
            content = <MultipleChoice qid={i} name={"q" + (i+1)} question={q.question} answers={q.options} grade={props.grade} onInput={handleInput} loading={props.loading} />;
        } else if (q.question.indexOf("$") !== -1) {
            content = <TextTemplate qid={i} grade={props.grade} onInput={handleInput} loading={props.loading} >{q.question}</TextTemplate>;
        } else {
            content = <TextResponse qid={i} question={q.question} grade={props.grade} onInput={handleInput} loading={props.loading} />;
        }
        let correct = props.grade && props.grades[i];
        if (correct) {
            numCorrect++;
        }

        out.push(
            <Question title={"Question " + (i+1)} result={props.grade ? correct : undefined} reason={props.grade ? props.gradeReasons[i] : undefined}>{content}</Question>
        );
    }
    if (props.grade) {
        out.push(<QuizGrade numCorrect={numCorrect} numTotal={data.questions.length} />);
    }
    out.push(<QuizButtons publishState={props.publishState} onQuizPublish={props.onQuizPublish} onNewPromptClick={props.onNewPromptClick} onGradeClick={() => {props.onGradeClick(answers);}} onQuizEditClick={props.onQuizEditClick} grade={props.grade} loading={props.loading} />);
    if (!props.grade) {
        out.push(<QuizEditButton onChange={props.onEditRequestChange} onClick={props.onEditRequestClick} loading={props.loading} />);
    }

    return (
        <div className="quiz_page">{out}</div>
    );
    // return (
    //     <div className="quiz_page">
    //         <h1>Assignment 1</h1>
    //         <Question title="Question 1" result={false}
    //                   reason="You need to do better next time. Some things that were wrong include a, b, and c.">
    //             <TextTemplate>I currently $ a bird in the tree. Yesterday, I $ a nice sunset.</TextTemplate>
    //         </Question>
    //         <Question title="Question 2" result={true}>
    //             <MultipleChoice name={"q1"} question="Which is the present tense conjugation?" answers={["A", "B", "C"]}
    //                             allowMultiple/>
    //         </Question>
    //         <Question title="Question 3">
    //             <TextResponse question="Are there more doors or wheels?"></TextResponse>
    //         </Question>
    //         <Question title="Question 4">
    //             <TextResponse question="What is your favorite word?" short></TextResponse>
    //         </Question>
    //         <button className="quiz_Button">Submit</button>
    //     </div>
    // );
}

function QuizButtons(props) {
    return (
        <div className="quiz_QuizButtons">
            {props.publishState === UNPUBLISHED ? <button className="quiz_Button" onClick={props.onNewPromptClick} disabled={props.loading}>Create New Quiz</button> : undefined}
            {props.grade ? undefined : <button className="quiz_Button" onClick={props.onGradeClick} disabled={props.loading}>Submit</button>}
            {(props.grade && props.publishState !== GRADED) ? <button className="quiz_Button" onClick={props.onQuizEditClick} disabled={props.loading}>Edit Quiz</button> : undefined}
            {props.publishState === UNPUBLISHED ? <button className="quiz_Button" onClick={props.onQuizPublish} disabled={props.loading}>Publish</button> : undefined}
        </div>
    );
}

function Question(props) {
    const [flagged, setFlagged] = useState(false);

    function handleFlagClick() {
        setFlagged(!flagged);
    }

    return (
        <div className="quiz_Question">
            <QuestionContainer title={props.title}>{props.children}</QuestionContainer>
            <IconButton onClick={handleFlagClick} filled={flagged} result={props.result}/>
            {props.reason ? <p>{props.reason}</p> : undefined}
        </div>
    );
}

function IconButton(props) {
    function handleClick() {
        if (props.onClick) {
            props.onClick(this);
        }
    }

    return (
        <button
            style={props.result === undefined ? {color: "var(--textcolor)"} : (props.result ? {backgroundColor: "#00D022"} : {backgroundColor: "red"})}
            disabled={props.result !== undefined}
            className={props.filled ? ["material-symbols-outlined filled"] : ["material-symbols-outlined"]}
            onClick={handleClick}>{props.result === undefined ? "flag" : (props.result ? "check" : "close")}</button>
    );
}

function QuestionContainer(props) {
    return (
        <div className="quiz_QuestionContainer">
            <h1>{props.title}</h1>
            <div>{props.children}</div>
        </div>
    );
}

function TextTemplate(props) {
    const [answers, setAnswers] = useState((new Array(props.children.split("$").length-1)).fill(undefined));

    function handleInput(qid, value) {
        let list = [...answers];
        list[qid] = value;
        setAnswers(list);
        props.onInput(props.qid, list);
    }

    function parsePattern(text, key) {
        let arr = text.split(key);
        const num_inputs = arr.length - 1;
        for (let i = 0; i < num_inputs; i++) {
            arr.splice(1+i*2, 0, <TextTemplateInput qid={i} key={i} grade={props.grade} onInput={handleInput} />);
        }
        return arr;
    }

    return (
        <div className="quiz_TextTemplate">{parsePattern(props.children, "$")}</div>
    );
}

function TextTemplateInput(props) {
    function handleInput(e) {
        props.onInput(props.qid, e.target.value);
    }

    return (
        <input className="quiz_TextTemplateInput" disabled={props.grade} onInput={handleInput} />
    );
}

function MultipleChoice(props) {
    function handleInput(e) {
        props.onInput(props.qid, e.target.value);
    }

    function createInputs(answers) {
        let out = [];
        for (let i = 0; i < answers.length; i++) {
            out.push(
                <label key={i}>
                    <input type={props.allowMultiple ? "checkbox" : "radio"} name={props.name} disabled={props.grade} value={answers[i]} onInput={handleInput}></input>
                    {answers[i]}
                </label>
            );
        }
        return out;
    }

    return (
        <div className="quiz_MultipleChoice">
            <p>{props.question}</p>
            {createInputs(props.answers)}
        </div>
    );
}

function TextResponse(props) {
    function handleInput(e) {
        props.onInput(props.qid, e.target.value);
    }

    return (
        <div className="quiz_TextResponse">
            <p>{props.question}</p>
            {props.short ? <input disabled={props.grade} /> : <textarea rows={6} disabled={props.grade} onInput={handleInput} />}
        </div>
    );
}

function QuizPrompt(props) {
    return (
        <div className="quiz_page quiz_promptpage">
            <h1>Enter Quiz Prompt</h1>
            <textarea className="quiz_Prompter" rows={8} onChange={e => props.onPromptChange(e.target.value)} />
            <button className="quiz_Button" onClick={props.onClick} disabled={props.loading}>Generate Quiz</button>
            {props.error ? <ErrorBanner>{props.error}</ErrorBanner> : undefined}
        </div>
    );
}

// numCorrect, numTotal
function QuizGrade(props) {
    let percent = Math.round((props.numCorrect / props.numTotal)*10000)/100;
    let letter = "F";
    if (percent >= 93) {
        letter = 'A';
    } else if (percent >= 90) {
        letter = 'A-';
    } else if (percent >= 87) {
        letter = 'B+';
    } else if (percent >= 83) {
        letter = 'B';
    } else if (percent >= 80) {
        letter = 'B-';
    } else if (percent >= 77) {
        letter = 'C+';
    } else if (percent >= 73) {
        letter = 'C';
    } else if (percent >= 70) {
        letter = 'C-';
    } else if (percent >= 67) {
        letter = 'D+';
    } else if (percent >= 63) {
        letter = 'D';
    } else if (percent >= 60) {
        letter = 'D-';
    }

    return (
        <div className="quiz_QuizGrade">
            <span>{props.numCorrect}</span>
            <span>/</span>
            <span>{props.numTotal}</span>
            <span>{percent + "%"}</span>
            <span>{letter}</span>
        </div>
    );
}

function ErrorBanner(props) {
    return (
        <div className="quiz_ErrorBanner">
            <div className="material-symbols-outlined filled">error</div>
            <p>{props.children}</p>
        </div>
    );
}

function QuizEditButton(props) {
    const [dialogOpen, setDialogOpen] = useState(false);

    function handleClick() {
        ref.current?.showModal();
        setDialogOpen(true);
    }

    function handleDialogClick(e) {
        const r = ref.current?.getBoundingClientRect();
        if (
            e.clientX > r.left &&
            e.clientX < r.right &&
            e.clientY > r.top &&
            e.clientY < r.bottom
        ) return;
        ref.current?.close();
        setDialogOpen(false);
    }

    const ref = useRef(null);

    const editForm = (
        <dialog ref={ref} onClick={handleDialogClick} open={dialogOpen} hidden={!dialogOpen}>
            <p>Request changes to the quiz</p>
            <textarea className="quiz_Prompter" rows={8} onChange={e => props.onChange(e.target.value)}/>
            <button className="quiz_Button" onClick={props.onClick} disabled={props.loading}>Request Edit</button>
        </dialog>
    );

    return (
        <div className="quiz_QuizEditButtonContainer">
            <button className="material-symbols-outlined filled quiz_QuizEditButton" onClick={handleClick}>edit</button>
            {editForm}
        </div>
    );
}

export default QuizManager;