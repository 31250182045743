import "./main.css";

function Main() {
    return (
        <div className="main">
            <div className="main_TopBar">
                <div className="main_BarTitle"><TitleText a="G" b="M"/></div>
                <Link href="https://forms.gle/PL5vy28z8esuBj58A">Take Our Survey!</Link>
                <Link href="/teacher">Teacher Portal</Link>
            </div>
            <div className="main_MainTitle"><TitleText a="Grade" b="Me"/></div>
            <div className="main_MainDesc">
                When teaching a foreign language, managing assignments should be the easiest part.<br/><br/>GradeMe is a service for professors teaching English to easily manage, create, and grade language assignments. GradeMe employs AI algorithms to personalize assignments for your students and their current level of fluency. GradeMe also tracks grades for these assignments, acting as your all-in-one grade book for any situation.
            </div>
            <ContactForm />
        </div>
    );
}

function TitleText(props) {
    return (
        <div className="main_TitleText">
            <span>{props.a}</span><span>{props.b}</span>
        </div>
    );
}

function Link(props) {
    return (
        <a className="main_Link" href={props.href}>{props.children}</a>
    );
}

function ContactForm() {
    return (
        <div className="main_ContactForm">
            <h1>Contact Us!</h1>
            <FormInput>Name</FormInput>
            <FormInput>Email</FormInput>
            <FormInput>Message</FormInput>
            <button className="main_Link">Submit</button>
        </div>
    );
}

function FormInput(props) {
    return (
        <div className="main_FormInput">
            <div>{props.children}</div>
            <input />
        </div>
    );
}

export default Main;