import './App.css';

import {BrowserRouter, Routes, Route} from "react-router-dom";

import Main from "./main.js";
import QuizManager from "./quiz.js";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="teacher" element={<QuizManager />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
